import React from 'react';
import { Box, Grid, Container, Typography, Divider } from '@mui/material';
import { OpenInNew, ChevronRight } from '@mui/icons-material';
import { Logo } from './Logo';
import { AppPromote } from './AppPromote';
import { NavLink } from 'react-router-dom';
const Footer = () => {
  return (
    <Box sx={{ backgroundColor: 'primary.main', color: '#fff' }}>
      <Container
        maxWidth="lg"
        sx={{ paddingLeft: { xs: '0', md: 3 }, paddingRight: { xs: '0', md: 3 } }}
      >
        <Grid container sx={{ mt: 4 }}>
          <Grid
            item={true}
            xs={12}
            md={4}
            sx={{ alignItems: 'center', img: { paddingLeft: { xs: 3, md: '0' } } }}
          >
            <Logo />
          </Grid>
          <Grid
            item={true}
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'left', md: 'flex-end' },
              alignItems: 'center'
            }}
          >
            <Box
              component="ul"
              sx={{
                width: { xs: '100%', md: 'initial' },
                listStyle: 'none',
                display: { xs: 'block', md: 'flex' },
                margin: 0,
                paddingLeft: 0,
                mt: { xs: 3, md: 0 },
                '& li': {
                  minWidth: { xs: '100%', md: 'initial' },
                  mx: { xs: 0, sm: 1 },
                  textAlign: { xs: 'left', md: 'right' },
                  marginLeft: '0 !important',

                  '& a': {
                    fontWeight: 600,
                    color: '#fff',
                    padding: { xs: '0 32px', md: '0' },
                    display: { xs: 'flex', md: 'inline-block' },
                    alignItems: 'center',
                    justifyContent: { xs: 'space-between' },
                    '& svg': { display: { md: 'none' } },
                    ml: { md: 5 },
                    '&:hover': {
                      textDecoration: 'underline'
                    },
                    py: { xs: 2, md: 0 },
                    borderTop: { xs: '1px solid #335E7C', md: 0 },
                    width: { xs: '100%', md: 'initial' }
                  },
                  '& a:last-child': {
                    borderBottom: { xs: '1px solid #335E7C', md: 0 }
                  }
                }
              }}
            >
              <li>
                <Typography
                  sx={{
                    fontWeight: 600
                  }}
                >
                  <NavLink to="/contact-us">
                    <span>Contact Us</span>
                    <ChevronRight />
                  </NavLink>
                  <NavLink to="/resources">
                    <span>Resources</span>
                    <ChevronRight />
                  </NavLink>
                  <NavLink to="/guides-templates">
                    <span>Guides & Templates</span>
                    <ChevronRight />
                  </NavLink>
                  <NavLink to="/major-cpd-providers">
                    <span>Major CPD Providers</span>
                    <ChevronRight />
                  </NavLink>
                  <a
                    href="https://www.racgp.org.au/education/professional-development/cpd/2023-triennium/faqs-for-gps#utm_source=CPD+home+page+tile+faqs&utm_medium=CPD+home+page+&utm_campaign=CPD+2023&utm_id=CPD+2023&utm_conte"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    <span>FAQ</span>
                    <OpenInNew
                      sx={{
                        fontSize: '16px',
                        ml: { md: 1 },
                        mr: { xs: 1, md: 0 },
                        display: { xs: 'flex', md: 'inline !important' }
                      }}
                    />
                  </a>
                </Typography>
              </li>
            </Box>
          </Grid>
        </Grid>
        <Divider
          sx={{
            borderColor: 'rgba(161, 194, 222, 0.34)',
            my: 4,
            display: { xs: 'none', md: 'block' }
          }}
        />

        <Grid
          container
          sx={{
            flexDirection: { xs: 'column-reverse', md: 'row' },
            mb: 2,
            pt: { xs: 2, md: 0 },
            paddingLeft: { xs: 3, md: 0 },
            paddingRight: { xs: 3, md: 0 }
          }}
        >
          <Grid item={true} xs={12} md={9} sx={{ mb: 3 }}>
            <Box
              component="ul"
              sx={{
                listStyle: 'none',
                display: { xs: 'block', md: 'flex' },
                margin: 0,
                paddingLeft: 0,
                mb: 2,
                textAlign: 'left',
                '& li:first-of-type': { '& a': { pl: 0 } },
                '& li:last-of-type': { '&:after': { content: '""' } },
                '& li': {
                  display: 'inline',
                  mx: 0,
                  '&:after': { content: '"|"' },
                  '& a': {
                    fontSize: 'small',
                    color: '#fff',
                    pr: 1,
                    pl: 1,

                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }
                }
              }}
            >
              <li>
                <a
                  href="https://www.racgp.org.au/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and conditions
                </a>
              </li>
              <li>
                <a href="https://www.racgp.org.au/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.racgp.org.au/partner-terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sponsor conditions
                </a>
              </li>
              <li>
                <a
                  href="https://www.racgp.org.au/education/professional-development/courses/delegate-conditions-and-cancellation-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Delegate conditions
                </a>
              </li>
              <li>
                <a href="https://www.racgp.org.au/licence-terms" target="_blank" rel="noreferrer">
                  License terms
                </a>
              </li>
            </Box>
            <Typography
              sx={{
                fontSize: 'small',
                color: '#fff'
              }}
            >
              &copy; {new Date().getFullYear()} The Royal Australian College of General
              Practitioners (RACGP) ABN 34 000 223 807 | v{process.env.REACT_APP_GIT_TAG}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md={3}>
            <Typography
              sx={{
                color: '#fff'
              }}
            >
              Download the RACGP myCPD&nbsp;App
            </Typography>
            <AppPromote />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Footer;
