import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TooltipContent = () => {
  return (
    <Tooltip title="If you have additional fellowships from an AMC-accredited specialist college that leads to specialist registration, you can access and manage your additional requirements for your fellowships.">
      <InfoOutlinedIcon fontSize="small" sx={{ ml: 2 }} />
    </Tooltip>
  );
};
export { TooltipContent };
