import { NavLink } from 'react-router-dom';
import { Typography, MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import InputIcon from '@mui/icons-material/Input';

const DrawerMenuMyAccount = () => {
  return (
    <>
      <a href={process.env.REACT_APP_MEMBER_HOME_URL || '#'}>
        <MenuItem sx={{ py: 1, px: 2, mt: 1 }}>
          <HomeIcon sx={{ height: 20, color: '#757575', mr: 2 }} />
          <Typography variant={'body2'}>Member home</Typography>
        </MenuItem>
      </a>
      <a href={process.env.REACT_APP_GP_LEARNING_LINK || '#'} rel="noreferrer" target="_blank">
        <MenuItem sx={{ py: 1, px: 2 }}>
          <LocalLibraryIcon sx={{ height: 20, color: '#757575', mr: 2 }} />
          <Typography variant={'body2'}>gplearning</Typography>
        </MenuItem>
      </a>
      <NavLink
        to={{
          pathname: '/notifications',
          state: { openSettings: true }
        }}
      >
        <MenuItem sx={{ py: 1, px: 2 }}>
          <NotificationsActiveIcon sx={{ height: 20, color: '#757575', mr: 2 }} />
          <Typography variant={'body2'}>Notifications settings</Typography>
        </MenuItem>
      </NavLink>
      <NavLink to="/edit-interests">
        <MenuItem sx={{ py: 1, px: 2 }}>
          <PlaylistAddCheckIcon sx={{ height: 20, color: '#757575', mr: 2 }} />
          <Typography variant={'body2'}>Edit interests</Typography>
        </MenuItem>
      </NavLink>
      <a href={process.env.REACT_APP_OKTA_POST_SIGNOUT_URL ?? '/logout'}>
        <MenuItem sx={{ py: 1, px: 2 }}>
          <InputIcon sx={{ height: 20, color: '#757575', mr: 2 }} />
          <Typography variant={'body2'}>Logout</Typography>
        </MenuItem>
      </a>
    </>
  );
};
export { DrawerMenuMyAccount };
