import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  PaperProps,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';

import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { colors } from 'src/components/App/colors';
import { InlineBox } from 'src/components/Layout/InlineBox';
import { useCycles } from 'src/hooks/useCycles';
import { ContentPageTitle } from '../../ContentPageTitle/ContentPageTitle';
import { ActivityTable } from './ActivityTable';
import { PageViewport } from './types';
import { Alerts } from './Alerts';
import { getPageViewportText } from './utils';
import useFetchAllActivities from './useFetchAllActivities';

const StyledPaper = (props: PaperProps) => {
  return (
    <Paper
      elevation={0}
      {...props}
      sx={{
        backgroundColor: '#f8f8f8'
      }}
    />
  );
};

const HistoryPage = () => {
  const [keyword, setKeyword] = useState<string | undefined>();
  const { cyclesList, activeCycle, setActiveCycle } = useCycles();
  const activeCycleInt = parseInt(activeCycle);
  const pendingKeyword = useRef<string | undefined>();
  const [pageViewport, setPageViewport] = useState<PageViewport>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const { state } = useLocation<{ deleteId: string; updatedId: string }>();
  const history = useHistory();

  const { deleteId, updatedId } = state || {};

  const currentDate = new Date();
  const month = currentDate.getMonth();
  const hasDatePermission = 0 <= month && month <= 2;

  useEffect(() => {
    // clear out the state after x seconds after we come from quick log to here with either updatedId or deleteId to clear out the alert
    if (!deleteId && !updatedId) {
      return;
    }
    const index = setTimeout(() => {
      history.push('#', { replace: true, state: {} });
    }, 7000);
    return () => {
      clearTimeout(index);
    };
  }, [deleteId, history, updatedId]);

  const [typeOfCPD, setTypeOfCPD] = useState<string[]>([]);
  const [selfRecordedOnly, setSelfRecordedOnly] = useState<boolean>(false);

  const fetchActivities = useFetchAllActivities(activeCycle);
  const onExecuteSearch = () => {
    setKeyword(pendingKeyword?.current);
  };

  return (
    <>
      <ContentPageTitle currentPageTitle="History" />
      <Container maxWidth="lg">
        <Paper elevation={2} sx={{ width: '100%', p: { xs: 2, md: 3 }, mt: 4, mb: 3 }}>
          <Grid container>
            <SearchBar
              keyword={keyword}
              onSelectKeyword={(selectedKeyword) => {
                pendingKeyword.current = selectedKeyword;
              }}
              onExecuteSearch={onExecuteSearch}
              suggestedKeywordsList={[]}
            />
            <Typography ml={{ sm: 0, md: 2 }} variant={'caption'} mt={0.5}>
              Search by Provider name, Activity title and Activity ID
            </Typography>
          </Grid>
        </Paper>
        <Box
          flexWrap={'wrap-reverse'}
          mb={2}
          display={'flex'}
          justifyContent={'space-between'}
          gap={2}
          alignItems={'center'}
        >
          <Box>
            <Typography color={'primary'} variant={'body1'}>
              Results {keyword ? `for "${keyword}"` : ''}
            </Typography>
            {pageViewport && (
              <Typography variant={'body2'} color={colors['charcoal.800']}>
                {getPageViewportText(pageViewport)}
              </Typography>
            )}
          </Box>

          {matches && <Box width={'100%'} />}

          <Box display={'flex'} gap={2} flex={{ xs: 1, sm: 0 }}>
            <Autocomplete
              PaperComponent={StyledPaper}
              limitTags={2}
              value={typeOfCPD}
              onChange={(event, value) => {
                setTypeOfCPD(value);
              }}
              multiple={true}
              fullWidth
              disablePortal={true}
              options={['EA', 'RP', 'MO']}
              disableCloseOnSelect
              renderOption={(props, optionValue) => {
                return (
                  <li {...props}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <Typography variant="body2">{optionValue}</Typography>
                    </Box>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Type of CPD" placeholder="Type of CPD" />
              )}
              sx={{
                '&.MuiAutocomplete-fullWidth': { maxWidth: { sm: '287px', lg: 'initial' } },
                '& .MuiAutocomplete-tag': { maxWidth: { xs: '100%', sm: '170px' } },
                minWidth: { sm: 270, xs: 0 },
                backgroundColor: 'white'
              }}
            />

            <Select
              value={activeCycle}
              onChange={(event) => setActiveCycle(event.target.value)}
              sx={{
                color: colors['charcoal.700'],
                bgcolor: 'white',
                flex: { sm: 0, xs: 1 }
              }}
            >
              {cyclesList.map((cycle) => {
                return (
                  <MenuItem value={cycle} key={cycle}>
                    {cycle}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>

        <Paper elevation={2} sx={{ width: '100%', p: { xs: 0, md: 3 } }}>
          <Box p={{ xs: 2, md: 0 }}>
            <Box
              mb={1.5}
              display={'grid'}
              alignItems={'center'}
              sx={{
                gridTemplateAreas: {
                  sm: `
                  "switch link"
                  `,
                  xs: `
                  " link"
                  "switch "
                  `
                },
                gridTemplateColumns: { sm: `1fr 1fr`, xs: `1fr` }
              }}
            >
              <FormControlLabel
                sx={{ gridArea: 'switch' }}
                control={
                  <Switch
                    value={selfRecordedOnly}
                    onChange={(_, checked) => setSelfRecordedOnly(checked)}
                  />
                }
                label="Self-Recorded Logs"
              />

              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  sx={{ gridArea: 'link' }}
                  color="primary"
                  variant="text"
                  size="medium"
                  component="a"
                  href={process.env.REACT_APP_PENDING_ACTIVITIES_URL}
                  target=""
                >
                  View Activities in progress <ChevronRight />
                </Button>
              </Box>
            </Box>

            <Alerts updatedId={updatedId} deletedId={deleteId} />

            {activeCycleInt === 2024 && hasDatePermission && (
              <Alert sx={{ my: 1.5 }} severity={'info'}>
                <AlertTitle sx={{ marginBottom: 0 }}>
                  <Typography variant={'body2'} component={'span'} mb={0}>
                    You can move a 2024 activity to 2023 to fulfil your CPD requirements by clicking
                    the Backdate button. This is available until{' '}
                    <InlineBox fontWeight={600}>March 31st</InlineBox>.
                  </Typography>
                </AlertTitle>
              </Alert>
            )}

            <Box
              sx={{
                padding: '14px 16px',
                border: '1px #CCCCCC solid',
                borderRadius: '4px',
                background: '#FEFEFE',
                color: 'rgba(0, 0, 0, 0.60)',
                marginBottom: '12px',
                marginTop: '12px'
              }}
            >
              <Typography variant={'body2'} component={'span'}>
                External Provider activities may take up to{' '}
                <InlineBox fontWeight={600}>30 days</InlineBox> before appearing in your history.
              </Typography>
            </Box>
          </Box>

          <Box pb={{ xs: 3, md: 0 }}>
            <ActivityTable
              items={fetchActivities.allActivities}
              cpdTypes={typeOfCPD}
              searchKeyword={keyword}
              cycle={activeCycleInt}
              onViewPortChanged={setPageViewport}
              selfRecordedOnly={selfRecordedOnly}
              loading={fetchActivities?.loading}
            />
          </Box>
        </Paper>
      </Container>
    </>
  );
};
export { HistoryPage };
