import React from 'react';
import { Box, Typography, Paper, useTheme, Stack, Link } from '@mui/material';
import Image from 'src/assets/banner-images/ad-banner-hero.jpg';
import GPLearningLogo from 'src/assets/provider-logos/gp-learning-logo.png';

interface Props {
  heading: string;
  description: string;
  buttons: {
    cta: string;
    link: { url: string; target: string };
  }[];
  image: {
    portrait: { position: string; size: string };
    square: { position: string; size: string };
    landscape: { position: string; size: string };
  };
}

const AdBanner: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          backgroundImage: `url(${Image})`,
          backgroundRepeat: 'no-repeat',
          width: '100%',
          backgroundPosition: {
            xs: props.image.portrait.position,
            sm: props.image.square.position,
            md: props.image.landscape.position
          },
          backgroundSize: {
            xs: props.image.portrait.size,
            md: props.image.landscape.size
          }
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} width={{ md: '60%' }}>
          <Box
            sx={{
              p: { xs: 2, md: 5 },
              backgroundColor: theme.palette.primary.main
            }}
            borderRadius={{ xs: '4px 4px 120px 4px', md: '4px 0px 120px 4px' }}
          >
            <Typography variant="h2" sx={{ color: 'white' }}>
              {props.heading}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, color: 'white' }}>
              {props.description}
            </Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
              {props.buttons.map((button: any) => {
                return (
                  <Link
                    sx={{
                      padding: '6px 16px',
                      backgroundColor: theme.palette.secondary.light,
                      fontSize: '1rem',
                      fontWeight: 500,
                      lineHeight: '1.75',
                      borderRadius: '100px',
                      textDecoration: 'none',
                      mr: 2,
                      cursor: 'pointer'
                    }}
                    href={button.link.url}
                    target={button.link.target}
                  >
                    {button.cta}
                  </Link>
                );
              })}

              <img src={GPLearningLogo} alt="logo" style={{ height: '30px' }} />
            </Stack>
          </Box>

          <Box height={{ xs: '20rem', md: 'initial' }}></Box>
        </Stack>
      </Paper>
    </Box>
  );
};
export { AdBanner };
