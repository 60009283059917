import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Divider, Typography, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotificationsIcon from '@mui/icons-material/Notifications';

const drawerItems = [
  {
    title: 'myCPD',
    to: '/mycpd'
  },
  {
    title: 'Browse',
    to: '/browse'
  },
  {
    title: 'Log',
    to: '/log',
    dropdown: {
      id: 'dropdown-log',
      items: [
        { label: 'Quick log', link: { url: '/log' }, isExternal: false },
        {
          label: 'CPR',
          link: {
            url: process.env.REACT_APP_RECORD_CPR,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'Advanced life support',
          link: {
            url: process.env.REACT_APP_RECORD_ALS,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'GP-led activity',
          link: {
            url: process.env.REACT_APP_RECORD_GLA,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'Measuring outcomes tool',
          link: {
            url: process.env.REACT_APP_QUALITY_IMPROVEMENT_TOOL,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'Reviewing performance tool',
          link: {
            url: process.env.REACT_APP_REFLECTION_TOOL,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'Specific requirement',
          link: {
            url: process.env.REACT_APP_RECORD_SR,
            target: '',
            isExternal: true
          }
        },
        {
          label: 'Activities in progress',
          link: {
            url: process.env.REACT_APP_PENDING_ACTIVITIES_URL,
            target: '',
            isExternal: true
          }
        }
      ]
    }
  },
  {
    title: 'History',
    to: '/history'
  },
  {
    title: 'Bookmarks',
    to: '/bookmarks',
    IconComponent: BookmarkIcon
  },
  {
    title: 'Notifications',
    to: '/notifications',
    IconComponent: NotificationsIcon
  }
];

export const DrawerMenu = ({ onClose }: { onClose: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  const DropdownLink = ({ item }: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {item.LinkIcon && (
          <item.LinkIcon sx={{ width: '1.5rem', height: '1.5rem', color: 'primary.main', mr: 2 }} />
        )}
        <span style={{ flexGrow: 1 }}> {item.label}</span>
      </Box>
    );
  };
  return (
    <Box sx={{ minWidth: 390 }}>
      <Box p={2} pr={3} display={'flex'} justifyContent={'flex-end'}>
        <CloseIcon cursor={'pointer'} onClick={onClose} />
      </Box>
      <Divider />
      <Box>
        {drawerItems.map((drawerItem) => {
          const { IconComponent, to, title } = drawerItem;

          return (
            <Box key={title}>
              {drawerItem.dropdown ? (
                <Box>
                  <Button
                    variant={'text'}
                    sx={{
                      p: 2,
                      pr: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: 0
                    }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <Box display={'flex'} gap={1}>
                      {IconComponent && (
                        <IconComponent
                          sx={{ maxHeight: 22, maxWidth: 22, color: 'primary.main' }}
                        />
                      )}
                      <Typography variant={'body1'}>{title}</Typography>
                    </Box>
                    {expanded ? (
                      <ExpandMoreIcon sx={{ height: 22 }} />
                    ) : (
                      <ChevronRightIcon sx={{ height: 22 }} />
                    )}
                  </Button>
                  <Collapse in={expanded}>
                    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                      {drawerItem.dropdown.items.map((item: any) => {
                        return item.link.isExternal ? (
                          <li>
                            <a
                              href={item.link.url}
                              target={item.link.target}
                              style={{
                                display: 'block',
                                width: '100%',
                                padding: `.5rem 1rem .5rem 1rem`
                              }}
                            >
                              <DropdownLink item={item} />
                            </a>
                          </li>
                        ) : (
                          <li>
                            <NavLink
                              onClick={onClose}
                              to={item.link.url}
                              style={{ display: 'block', padding: `.5rem 1rem .5rem 1rem` }}
                            >
                              <DropdownLink item={item} />
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </Collapse>
                </Box>
              ) : (
                <NavLink onClick={onClose} to={to}>
                  <Button
                    variant={'text'}
                    sx={{
                      p: 2,
                      pr: 3,
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      borderRadius: 0
                    }}
                  >
                    <Box display={'flex'} gap={1}>
                      {IconComponent && (
                        <IconComponent
                          sx={{ maxHeight: 22, maxWidth: 22, color: 'primary.main' }}
                        />
                      )}
                      <Typography variant={'body1'}>{title}</Typography>
                    </Box>
                    <ChevronRightIcon sx={{ height: 22 }} />
                  </Button>
                </NavLink>
              )}
              <Divider />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
