import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const sentryErrorMonitor = () => {
  Sentry.init({
    dsn: 'https://b80479e5e71944c3abddfe97b660ac37@o1007928.ingest.sentry.io/6657374', //paste copied DSN value here
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],

    release: process.env.REACT_APP_GIT_TAG,
    tracesSampleRate: 1.0 //lower the value in production
  });
};

function setSentryUserInfo(user: Sentry.User) {
  if (user && user.id) {
    Sentry.setUser(user);
  }
}

function clearSentryUserInfo() {
  Sentry.configureScope((scope) => scope.setUser(null));
}

export default sentryErrorMonitor;
export { setSentryUserInfo, clearSentryUserInfo };
