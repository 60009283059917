import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

type AuthRequiredModalType = React.PropsWithChildren & {
  authRequiredModalOpen: boolean;
  triggerLogin: () => void;
};

const AuthRequiredModal = ({ authRequiredModalOpen, triggerLogin }: AuthRequiredModalType) => {
  const closeModal = (event: object, reason: string) => {
    // prevent closing modal
    if (reason && ['escapeKeyDown', 'backdropClick'].includes(reason)) {
      return;
    }
    window.location.replace(process.env.REACT_APP_OKTA_POST_SIGNOUT_URL ?? window.location.origin);
  };

  const confirmModal = () => {
    // setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  if (!authRequiredModalOpen) {
    return <></>;
  }

  return (
    <Dialog onClose={closeModal} open={authRequiredModalOpen}>
      <DialogTitle>Authentication refresh required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>myCPD needs to update your authentication.</p>
          <p>Click yes to refresh your login.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => closeModal(event, '')}>No</Button>
        <Button onClick={() => confirmModal()}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
export { AuthRequiredModal };
