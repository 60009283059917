import { Box, Typography, Paper, Button } from '@mui/material';
import { InlineBox } from 'src/components/Layout/InlineBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { colors } from 'src/components/App/colors';

import { NavLinkButton } from './ButtonNavLink';
import { NavLink } from 'react-router-dom';

type RequirementDetails = {
  whatToDo: JSX.Element;
  description: JSX.Element;
  activityGuide: JSX.Element;
  quickLinks: JSX.Element;
};

const DRAWER_BUTTON_HEIGHT = 52;

const quickLinksText = () => {
  return (
    <>
      <Typography mb={2} variant={'h2'} sx={{ color: 'primary.main' }}>
        Record hours or find new activities
      </Typography>
      <Box display={'flex'} flexWrap={'wrap'}>
        <NavLinkButton
          sx={{ height: { xs: 'auto', sm: DRAWER_BUTTON_HEIGHT } }}
          icon={AddCircleOutlineIcon}
          buttonText={'Quick log to record CPD hours'}
          component={NavLink}
          to="/log"
        />

        <NavLinkButton
          sx={{ height: { xs: 'auto', sm: DRAWER_BUTTON_HEIGHT } }}
          icon={SearchIcon}
          buttonText={'Browse to find CPD activities'}
          component={NavLink}
          to="/browse"
        />
      </Box>
    </>
  );
};
const CPDActivityGuide = () => {
  const downloadURL = process.env.REACT_APP_CPD_ACTIVITY_DOWNLOAD_URI!;
  return (
    <>
      <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
        Download our CPD Activity Guide to view suggested activities.
      </Typography>
      <Paper
        elevation={0}
        variant={'outlined'}
        sx={{ my: 2, borderRadius: 2, borderColor: colors['stone.100'], width: '100%' }}
      >
        <Button
          component="a"
          href={downloadURL}
          target="_blank"
          fullWidth
          sx={{
            alignItems: 'center',
            display: 'flex',
            p: 2,
            justifyContent: 'space-between',
            borderRadius: 'initial',
            height: { xs: 'auto', sm: DRAWER_BUTTON_HEIGHT }
          }}
        >
          <Typography
            variant={'body1'}
            sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}
          >
            CPD Activity Guide
          </Typography>
          <Box display="flex" gap={1}>
            <Typography
              variant={'body1'}
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 500, color: 'primary.main' }}
            >
              Download
            </Typography>
            <FileDownloadOutlinedIcon />
          </Box>
        </Button>
      </Paper>
    </>
  );
};

export const getDetailsForCategoryGroup = (
  categoryGroupCode?: string
): RequirementDetails | undefined => {
  switch (categoryGroupCode) {
    case 'EA-BLA':
      return {
        whatToDo: (
          <Typography variant={'body2'} component={'div'}>
            Complete a minimum of <InlineBox fontWeight={600}>12.5 hours</InlineBox> of Educational
            Activities (EA).
          </Typography>
        ),
        description: (
          <Box>
            <Typography mb={1.5} variant={'body1'} fontWeight={600} sx={{ color: 'primary.main' }}>
              Educational Activities
            </Typography>
            <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
              These are activities that expand general practice knowledge, skills and attitudes,
              related to your scope of practice.
            </Typography>
          </Box>
        ),
        activityGuide: CPDActivityGuide(),
        quickLinks: quickLinksText()
      };
    case 'RPMO':
      return {
        whatToDo: (
          <Typography variant={'body2'} component={'div'}>
            Complete <InlineBox fontWeight={500}>a minimum of 25 hours</InlineBox> of Reviewing
            Performance (RP) and Measuring Outcomes (MO) activities, made up of{' '}
            <InlineBox fontWeight={500}> at least 5 hours</InlineBox> of RP and{' '}
            <InlineBox fontWeight={500}>5 hours</InlineBox> of MO. The{' '}
            <InlineBox fontWeight={500}>remaining 15 hours</InlineBox> can be completed in either RP
            or MO as best suits your scope of practice.
          </Typography>
        ),
        description: (
          <>
            <Box>
              <Typography mb={1.5} variant={'body1'} fontWeight={600}>
                Reviewing Performance
              </Typography>
              <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
                Activities that require reflection on your work to validate your current practice or
                identify areas for improvement. This may include feedback from patients, peers or
                even self-reflection.
              </Typography>
              <Typography mt={2} mb={1.5} variant={'body1'} fontWeight={600}>
                Measuring Outcomes
              </Typography>
              <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
                Activities that use your work data and best practice to ensure quality results. You
                could achieve this by conducting activities like research, audits or evaluations.
              </Typography>
            </Box>
          </>
        ),
        activityGuide: CPDActivityGuide(),
        quickLinks: quickLinksText()
      };
    case 'RPMOEU':
      return {
        whatToDo: (
          <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
            The balance of your CPD hours will be achieved by completing a minimum of{' '}
            <InlineBox fontWeight={500}>12.5 hours</InlineBox> from any of the three CPD types (EA,
            RP or MO).
            {'\n\n'}
            Hours will count under Any CPD Type once EA hours exceed 12.5 hours or RP hours exceed
            20 or MO hours exceed 20.
          </Typography>
        ),
        description: <></>,
        activityGuide: <></>,
        quickLinks: quickLinksText()
      };
    case 'BLS':
      return {
        whatToDo: (
          <Typography variant={'body2'} whiteSpace={'pre-wrap'}>
            The RACGP’s specialist high-level requirement for general practice is to complete a
            cardiopulmonary resuscitation (CPR) course at least once per triennium. The RACGP will
            also accept basic life support (BLS) and advanced life support (ALS) courses that
            include CPR. Neonatal CPR does not satisfy CPR requirements.
          </Typography>
        ),
        description: <></>,
        activityGuide: <></>,
        quickLinks: (
          <>
            <Typography mb={2} variant={'h2'} sx={{ color: 'primary.main' }}>
              Record hours or find new activities
            </Typography>
            <Box display={'flex'} flexWrap={'wrap'}>
              <NavLinkButton
                icon={AddCircleOutlineIcon}
                buttonText={'Record CPR as a self-directed activity'}
                component="a"
                href={process.env.REACT_APP_RECORD_CPR!}
                linkTarget="_blank"
              />
              <NavLinkButton
                icon={AddCircleOutlineIcon}
                buttonText={'Record ALS as a self-directed activity'}
                component="a"
                href={process.env.REACT_APP_RECORD_ALS!}
                linkTarget="_blank"
              />

              <NavLinkButton
                icon={SearchIcon}
                buttonText={'Browse to find CPD activities'}
                component={NavLink}
                to="/browse"
              />
            </Box>
          </>
        )
      };
    default:
      return undefined;
  }
};
