import { Alert, AlertTitle, Box, Container, Link, Typography } from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ICON_COLOR = 'rgb(42, 102, 198)';

type ErrorBoundaryFallbackProps = { error: Error | null };

export const ErrorBoundaryFallback = ({ error }: ErrorBoundaryFallbackProps) => {
  const errorMessage = error?.message;

  return (
    <Container maxWidth={'lg'}>
      <Box my={5}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage && (
            <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
              {errorMessage}
            </Typography>
          )}
          <br />
          <Typography variant={'body2'} component="div">
            Sorry but it looks like there is an unexpected issue with the website.
            <br />
            Please return to the <Link href="/">myCPD home page</Link> or try visiting the website
            at a later time.
            <br />
            <br />
            If you continue to get this error please let us know at:
            <br />
            <br />
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <MailOutlineIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'mailto: cpd@racgp.org.au'}> cpd@racgp.org.au</Link>
            </Box>
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <PhoneEnabledIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'tel:1800472247'}>1800 4RACGP | 1800 472 247</Link>
            </Box>
            <Box display={'flex'} mb={1} color={ICON_COLOR}>
              <PhoneEnabledIcon fontSize={'small'} sx={{ mr: 1 }} />
              <Link href={'tel:+61386990300'}>International +61 (03) 8699 0300</Link>
            </Box>
          </Typography>
        </Alert>
      </Box>
    </Container>
  );
};
