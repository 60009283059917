import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { ActivityActionsProps } from '../types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { colors } from 'src/components/App/colors';

export const LongFormActions = ({ activity }: ActivityActionsProps) => {
  const activitykeyUrlEncoded = encodeURIComponent(activity?.cpd_id ?? 'null');

  return (
    <>
      <MenuItem
        component={'a'}
        href={process.env.REACT_APP_SELF_DIRECTED_URL + activitykeyUrlEncoded}
        target="_blank"
        sx={{ py: 1, px: 2, mt: 1 }}
      >
        <VisibilityIcon fontSize={'small'} sx={{ mr: 1.5, color: colors['charcoal.700'] }} />
        <Typography variant={'body2'}>View</Typography>
      </MenuItem>
    </>
  );
};
